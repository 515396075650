export type ThemeType = typeof light // This is the type definition for my theme object.

export const light = {
  colors: {
    primary: "#1C1D1C",
    secondary: "#2B3722",
  },
  fonts: {
    primary: "#e3e8d5",
    secondary: "#B9D27A",
  },
  breakpoints: {
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    xxl: "1536px",
  },
} as const

// export const dark: ThemeType = {
// 	colors: {

// 	},
// 	breakpoints: {
// 		sm: "640px",
// 		md: "768px",
// 		lg: "1024px",
// 		xl: "1280px",
// 		xxl: "1536px",
// 	},
// } as const

const theme = light // set the light theme as the default.
export default theme
