import theme from '@/styles/theme.styles'
import { GlobalStyles } from '@/styles/global.styles'
import { ThemeProvider } from 'styled-components'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Analytics } from '@vercel/analytics/react'

import Script from 'next/script'

import { ReactNode } from 'react'

const queryClient = new QueryClient()

const App = ({ Component, pageProps }: any) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  return (
    <>
      <Script
        id="google-tag-manager"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=G-B60FZ6Q619`}
      />

      <Script
        id="google-tag-manager-data-layer"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-B60FZ6Q619', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <QueryClientProvider client={queryClient}>
        <GlobalStyles />
        <ThemeProvider theme={theme}>
          {getLayout(<Component {...pageProps} />)}
        </ThemeProvider>
      </QueryClientProvider>
      <Analytics />
    </>
  )
}

export default App
