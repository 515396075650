import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
    background: rgb(36,36,36);

  }

  body {
    line-height: 1.5;
    font-size: 1.6rem;


    background: #2B3722;
    background: linear-gradient(115deg, #232323 0%, #1c1d1a 100%);
    color: #fff;

    font-family: 'Roboto', sans-serif;
  }


  h1, h2, h3, h4, h5, h6 {
  }
  
  
  a {
    cursor: pointer;
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  button {
    cursor: pointer;
  }
  
  input, button {
    border: none;
    outline: none;
    font-family: inherit;
    &:focus {
      outline: none;
    }
  }

  input {
    font-size: 1.8rem;
  }
`
